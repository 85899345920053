import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';

import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {Box} from '@workday/canvas-kit-react/layout';
import {SystemIconCircle} from '@workday/canvas-kit-react/icon';
import {tokensIcon, componentIcon, imageIcon} from '@workday/canvas-system-icons-web';

import SEO from '../../src/components/SEO';
import {H1, H2, P} from '../../src/components/StyledElements';
import AnimatedIllustration from '../../src/components/content/AnimatedIllustration';
import {BespokeSection} from '../../src/components/content/BespokeSection';
import {CTACard, BorderlessCard, CardLink} from '../../src/components/content/Card';
import {HeadingWithKeyline} from '../../src/components/content/Keyline';
import {FAQNoticeCard} from '../../src/components/content/NoticeCard';
import {ResponsiveFlex} from '../../src/components/content/ResponsiveFlex';

import {Image} from '../../src/utils/types';
import illustration from '../../resources/lottie-animations/getting-started.json';

const Page = () => {
  const {
    allFile: {edges: images},
  } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^get-started/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const designImg = images.find((img: Image) => img.node.name === 'get-started-design')?.node;
  const devImg = images.find((img: Image) => img.node.name === 'get-started-dev')?.node;

  return (
    <Box paddingY={120}>
      <SEO title="Getting Started" />
      <BespokeSection>
        <Box margin="0 auto" maxWidth={500} css={{textAlign: 'center'}}>
          <H1>Introduction</H1>
          <p
            style={{
              ...type.levels.body.large,
              ...type.variants.hint,
              marginTop: space.m,
              marginBottom: space.l,
            }}
          >
            Brand new to the Workday Canvas Design System? <br />
            Begin your journey with the information below
          </p>
        </Box>
        <Box margin="auto" maxWidth="90%">
          <AnimatedIllustration data={illustration} />
        </Box>
        <H2>What is Canvas?</H2>
        <P css={{textAlign: 'left'}}>
          Canvas is Workday’s single source of truth for everything product development. Our design
          system is a collection of rules, principles, constraints and best practices. The core
          pieces of Canvas are our UI elements presented in both, visual and libraries code
          repositories.
        </P>
        <P css={{textAlign: 'left'}}>
          Our mission is to enable fast, sustainable design and development of unified user
          experiences. We do this by maintaining, supporting and evolving our foundational source of
          truth.
        </P>
      </BespokeSection>
      <BespokeSection>
        <HeadingWithKeyline keylineText="Discover">What Canvas is Made Of</HeadingWithKeyline>
        <ResponsiveFlex columnIfLessThan="xl">
          <BorderlessCard
            headingText="Tokens"
            icon={<SystemIconCircle icon={tokensIcon} background={colors.cantaloupe500} />}
            linkText="Learn More"
            linkTo="/tokens/overview"
          >
            <p>
              Atomic pieces of style information (color, typography, etc.). Their primary function
              is to store UI information that allows for building more complex elements.
            </p>
          </BorderlessCard>
          <BorderlessCard
            headingText="Assets"
            icon={<SystemIconCircle icon={imageIcon} background={colors.watermelon400} />}
            linkText="View and Download"
            linkTo="/assets/overview"
          >
            <p>
              Atomic visuals intended to convey a meaning. They can be used functionally (e.g.
              icons), or for presentation (e.g. illustrations).
            </p>
          </BorderlessCard>
          <BorderlessCard
            headingText="Components"
            icon={<SystemIconCircle icon={componentIcon} background={colors.blueberry400} />}
            linkText="Read Guidelines"
            linkTo="/components/overview"
          >
            <p>
              The generic and reusable building blocks that primarily make up any user experience.
              Components can be simple like a button, but can also be composed into something
              complex like a toolbar.
            </p>
          </BorderlessCard>
        </ResponsiveFlex>
      </BespokeSection>
      <BespokeSection>
        {/* <H4>Why do I need it?</H4>
        <P style={{maxWidth: 480, margin: '0 auto'}}>
          Design systems eliminate the need for teams to start from scratch when designing a new
          product or feature by providing them with the core building blocks that are reusable and
          quickly scalable for a variety of product use cases. This makes for unified user
          experiences, and happier, more efficient product teams.
        </P> */}
        <HeadingWithKeyline keylineText="Next Steps">Let&rsquo;s Dive In</HeadingWithKeyline>
        <P>
          Read on to learn how to best use our design system for your role. Now that you understand
          the fundamentals of Canvas, it’s time to jump in and start building!
        </P>
        <ResponsiveFlex columnIfLessThan="m" marginTop={space.xl}>
          <CTACard>
            <GatsbyImage
              image={getImage(designImg) as IGatsbyImageData}
              alt={`Development illustration`}
            />
            <h2>For Designers</h2>
            <P>Ready for designing with Canvas 101? Here we go!</P>
            <CardLink to="/get-started/for-designers/understanding-canvas">Get Started</CardLink>
          </CTACard>
          <CTACard>
            <GatsbyImage image={getImage(devImg) as IGatsbyImageData} alt={`Design illustration`} />
            <h2>For Developers</h2>
            <P>Ready for developing with Canvas? Dive into our resources!</P>
            <CardLink to="/get-started/for-developers/using-canvas">Get Started</CardLink>
          </CTACard>
        </ResponsiveFlex>
      </BespokeSection>
      <BespokeSection>
        <FAQNoticeCard />
      </BespokeSection>
    </Box>
  );
};

Page.title = 'Introduction';
Page.centerFooter = true;
Page.suppressTopJump = true;
Page.rank = 0;
Page.tags =
  'getting started, intro, designers, developers, pms, overview, canvas, elements, components, patterns, tokens, assets';

export default Page;
