import React from 'react';
import {CSSObject} from '@emotion/styled';
import {Box, Flex} from '@workday/canvas-kit-react/layout';
import {space} from '@workday/canvas-kit-react/tokens';
import {
  ViewportSize,
  combineMediaQueries,
  contentHSpace,
  maxBespokeContentWidth,
  mqAt,
  mqLessThan,
  mqGreaterThanOrEqual,
} from '../../utils/breakpoints';

export interface BespokeSectionProps {
  centered?: boolean;
  children: React.ReactNode;
  hasHorizontalPadding?: boolean;
}

const getBespokeSectionMediaQuery = (size: ViewportSize, hasHorizontalPadding: boolean) => {
  const hPadding = contentHSpace[size];

  return {
    [mqAt(size)]: {
      maxWidth: maxBespokeContentWidth + 2 * hPadding,
      paddingRight: hasHorizontalPadding ? hPadding : undefined,
      paddingLeft: hasHorizontalPadding ? hPadding : undefined,
    },
  };
};

/**
 * Use BespokeSection for pages which have BespokeCurvedSections such as the
 * Home page. BespokeCurvedSections occupy the full width of their container
 * (since they have a full-width background). Use BespokeSection for other
 * sections on the page to keep their content properly aligned with content in
 * the BespokeCurvedSections.
 */
export const BespokeSection = ({
  centered = true,
  children,
  hasHorizontalPadding = true,
  ...elemProps
}: BespokeSectionProps) => {
  const styles = {
    '&:first-of-type': {
      marginTop: 0,
      paddingTop: 0,
    },

    '&:last-of-type': {
      paddingBottom: 0,
    },

    ...combineMediaQueries(getBespokeSectionMediaQuery, hasHorizontalPadding),
  };

  return (
    <Box marginTop="xxxl" marginX={centered ? 'auto' : undefined} css={styles} {...elemProps}>
      {children}
    </Box>
  );
};

export interface BespokeSplitSectionProps extends Pick<BespokeSectionProps, 'centered'> {
  startContent: React.ReactNode;
  endContent: React.ReactNode;
  reverseColumn?: boolean;
}

/**
 * BespokeSplitSection is a BespokeSection with two equal-width slots of
 * content separated by a horizontal gap (space.xl).
 * TODO: We could probably use ResponsiveFlex here to simplify things.
 */
export const BespokeSplitSection = ({
  startContent,
  endContent,
  reverseColumn = false,
  ...elemProps
}: BespokeSplitSectionProps) => {
  const containerStyles: CSSObject = {
    [mqLessThan('xl')]: {
      flexDirection: reverseColumn ? 'column-reverse' : 'column',
    },
    [mqGreaterThanOrEqual('xl')]: {
      flexDirection: 'row',
    },
  };

  const itemStyles = {
    [mqLessThan('xl')]: {
      width: '100%',
      '&:not(:first-of-type)': {
        marginTop: reverseColumn ? undefined : space.xl,
        marginBottom: reverseColumn ? space.xl : undefined,
      },
    },
    [mqGreaterThanOrEqual('xl')]: {
      width: `calc((100% - ${space.xl}) / 2)`,
      '&:not(:first-of-type)': {
        marginLeft: space.xl,
      },
    },
  };

  return (
    <BespokeSection {...elemProps}>
      <Flex alignItems="center" css={containerStyles}>
        <Box css={itemStyles}>{startContent}</Box>
        <Box css={itemStyles}>{endContent}</Box>
      </Flex>
    </BespokeSection>
  );
};
